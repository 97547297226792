
import {axioService} from "./axioService";
import {config} from "../constants/config";


export const searchBoxService = {
    getSearchMaster,
    getWhatsNew,
    getUnderConsideration,
    getTrending,
    getStatistics,
    getAllUnderConsideration,
    
}

function getSearchMaster() {    
    let axiosInst = axioService.getAxioInstance();
    return axiosInst.get(`${config.apiUrl}/Search/GetSearchMaster`)
        .then(res => res);
}

function getWhatsNew(mockMode = false) {
    if (mockMode) {
        // dummy return date.
        return new Promise((resolve) => {
            setTimeout(() => {
                const dummyResult = { status: 200};
                resolve(dummyResult);
            }, 2000);
        }, reject => { });
    }
    else {
        let axiosInst = axioService.getAxioInstance();
        return axiosInst.get(`${config.apiUrl}/Search/GetWhatsNew`)
            .then(res => res);
    }
}

function getUnderConsideration(id=0,mockMode = false) {
    if (mockMode) {
        // dummy return date.
        return new Promise((resolve) => {
            setTimeout(() => {
                const dummyResult = { status: 200, data: {message:"testMessage",data:"mockData"} };
                resolve(dummyResult);
            }, 2000);
        }, reject => { });
    }
    else {
        let axiosInst = axioService.getAxioInstance();
        return axiosInst.get(`${config.apiUrl}/Search/GetUpComingHearings/${id}`)
            .then(res => res);
    }
}

function getAllUnderConsideration(id=0,mockMode = false) {
    if (mockMode) {
        // dummy return date.
        return new Promise((resolve) => {
            setTimeout(() => {
                const dummyResult = { status: 200, data: {message:"testMessage",data:"mockData"} };
                resolve(dummyResult);
            }, 2000);
        }, reject => { });
    }
    else {
        let axiosInst = axioService.getAxioInstance();
        return axiosInst.get(`${config.apiUrl}/Search/GetAllUpComingHearings/${id}`)
            .then(res => res);
    }
}

function getTrending(mockMode = false) {
    if (mockMode) {
        // dummy return date.
        return new Promise((resolve) => {
            setTimeout(() => {
                const dummyResult = { status: 200, data: {message:"testMessage",data:"mockData"} };
                resolve(dummyResult);
            }, 2000);
        }, reject => { });
    }
    else {
        let axiosInst = axioService.getAxioInstance();
        return axiosInst.get(`${config.apiUrl}/Search/GetTrendingList`)
            .then(res => res);
    }
}

function getStatistics(councilPeriodId=0,mockMode = false) {
    if (mockMode) {
        // dummy return date.
        return new Promise((resolve) => {
            setTimeout(() => {
                const dummyResult = { status: 200, data: {message:"testMessage",data:"mockData"} };
                resolve(dummyResult);
            }, 8000);
        }, reject => { });
    }
    else {
        let axiosInst = axioService.getAxioInstance();
        return axiosInst.get(`${config.apiUrl}/Search/GetStatisticSummary/${councilPeriodId}`)
            .then(res => res);
    }
}

