import React,{Component,Fragment,useEffect,useState} from 'react';
import SearchBox from "./SerachBox";
import NumberDisplayer from "./NumberDisplayer"
import NewsDisplayer from "./NewsDisplayer";

export function HomeSearchHeader(props) {
    
    return (
        <div className="intro home-header" >
            <div className="shell">
				<div className="intro__head">
					<h1 className="h1">Legislative Information Management System (LIMS)</h1>
				</div>
                <SearchBox
                    match = {props.match}
                    history = {props.history} 
                    refineBy = {JSON.stringify([])}
                ></SearchBox>
                <NumberDisplayer history = {props.history}/>

        </div>
    </div>
    );
    


}