import React,{Component,Fragment,useEffect,useState} from 'react';
import SearchBox from "./SerachBox";
import NumberDisplayer from "./NumberDisplayer"
import NewsDisplayer from "./NewsDisplayer";
import {HomeSearchHeader} from "./HomeSearchHeader";
import { utility } from '../../helpers/utility';

export function Home (props){
   document.title="District of Columbia: Legislative Information Management System Home";

   useEffect(()=>{
       let newPath = utility.loadState("tracePath");
       if(newPath != null){
           sessionStorage.removeItem("tracePath");
       }
       window.ga('send', {
            hitType: 'pageview',location: `${document.location.href}`,
            page:`${document.location.pathname}`,
            title:"DC Legislation Information Management System",
        });
   },[]);

    return (
        <Fragment>
            <div className="main">
                    
                <HomeSearchHeader history = {props.history}/>
                <NewsDisplayer />
            </div>
        </Fragment>
    );
}

