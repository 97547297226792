import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Responsive, Menu, Icon, Dropdown } from "semantic-ui-react";

export default function (props) {
    const currentURL =  window.location.pathname || "";
    //console.log(currentURL);
    
    function handleSkip(e, targetId) {
        if (e.key === "Enter" || e.key === " " || e.key === undefined) {
            let result = document.getElementById(targetId);
            if (result != null) {
                result.focus();
                result.scrollIntoView();
            }
        }
    }



    return (
        <Fragment>
            <header className="header no_print">
                <div className="shell">
                    <div className="header__inner">
                        <div className="header__head">
                        {currentURL === "/" && 
                        <div className="skip-to-main-div no_print">
                            <button className="skip-to-main-a" type="button" 
                                aria-label = "Skip to Search Legislation"
                            onClick={
                                (e)=>{
                                    handleSkip(e,"search_legislation_input");
                                }
                            }>Skip to Search Legislation</button>
                        </div>
                        }
                        {currentURL === "/voteSearch" && 
                        <div className="skip-to-main-div no_print">
                            <button className="skip-to-main-a" type="button" 
                                aria-label = "Skip to Vote Search Result"
                            onClick={
                                (e)=>{
                                    handleSkip(e,"vote-search-result");
                                }
                            }>Skip to Vote Search Result</button>
                        </div>
                        }
                        {currentURL.includes("/Legislation") && 
                        <div className="skip-to-main-div no_print">
                            <button className="skip-to-main-a" type="button" 
                                aria-label = "Skip to legislation detail"
                            onClick={
                                (e)=>{
                                    handleSkip(e,"legislation-detail-title");
                                }
                            }>Skip to legislation detail</button>
                        </div>
                        }
                        {currentURL.includes("/introducerDetail") && 
                        <div className="skip-to-main-div no_print">
                            <button className="skip-to-main-a" type="button" 
                                aria-label = "Skip to introducer detail"
                            onClick={
                                (e)=>{
                                    handleSkip(e,"introducer-detail-name");
                                }
                            }>Skip to introducer detail</button>
                        </div>
                        }
                            <a href = "https://dccouncil.gov" className="logo" aria-label="Visit DC Council Website" title = "Visit DC Council Website">
                                <span className="logo-image"></span>
                                <span className="logo-text"></span>
                            </a>
                        </div>

                        <div className="header__content">
                            <Responsive minWidth={667}>
                                <nav className="nav">
                                    <ul>
                                        <li >
                                            <Link className="header__link_item" to="/voteSearch" aria-label="Councilmember Voting Search">Councilmember Voting Search</Link>
                                        </li>
                                        <li >
                                            <a className="header__link_item" href="/api/help/index.html" aria-label="Access LIMS Data (API)">Access LIMS Data (API)</a>
                                        </li>
                                    </ul>
                                </nav>
                            </Responsive>
                            <Responsive maxWidth={666}>
                                <nav className="nav">
                                    <Dropdown
                                        direction="left"
                                        icon='bars'
                                        floating
                                        button
                                        className='icon'
                                    >
                                        <Dropdown.Menu>
                                            <Dropdown.Menu scrolling>
                                                <Dropdown.Item key={1} >
                                                    <Link className="header__link_item" to="/voteSearch" aria-label="Councilmember Voting Search">Councilmember Voting Search</Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item key={2} >
                                                    <a className="header__link_item" href="/api/help/index.html" aria-label="Access LIMS Data (API)">Access LIMS Data (API)</a>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                </nav>
                            </Responsive>
                        </div>
                    </div>
                </div>
            </header>
        </Fragment>
    );
} 