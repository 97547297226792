import React, { Component, Fragment, useState, useEffect, useRef } from "react";
import { Dropdown, Icon, Dimmer, Loader, Segment, Responsive, Input, Button, Popup, Message } from "semantic-ui-react";
import { searchBoxService } from "../../services/searchBoxService";
import Swal from "sweetalert2";
import { utility } from "../../helpers/utility";
import _ from "lodash";
import { Link } from "react-router-dom";
import { queryConfig } from "../../constants/config";

export default function (props) {
    const queryString = require("query-string");
    const [advanceSearch, setAdvanceSearch] = useState(false);
    const [masterData, setmasterData] = useState(props.masterData ? props.masterData : undefined);
    const [searchQueryString, setSearchQueryString] = useState(
        props.searchQueryString ? props.searchQueryString : undefined
    );
    const [isLoading, setIsLoading] = useState(true);
    const [searchString, setSearchString] = useState(props.viewModel ? props.viewModel.searchString : "");
    const [legislationCategoryId, setLegislationCategoryId] = useState(null);
    const [legislationSubCategoryId, setLegislationSubCategoryId] = useState(null);
    const [councilPeriodId, setCouncilPeriodId] = useState(0);
    const [requestorId, setRequestorId] = useState(null);
    const [introducer, setIntroducer] = useState([]);
    const [coSponsor, setCoSponsor] = useState([]);
    const [committee, setCommittee] = useState([]);
    const [committee_w_Comments, setCommittee_w_Comments] = useState([]);
    const [statusId, setStatusId] = useState(null);
    const [shouldUpload, setShouldUpload] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [isSubmit, setIsSubmit] = useState(false);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [subCategoryOptions, setSubCategoryOptions] = useState([]);
    const [councilPeriodOptions, setCouncilPeriodOptions] = useState([]);
    const [requestorOptions, setRequestorOptions] = useState([]);
    const [committeeOptions, setCommitteeOptions] = useState([]);
    const [statusOptions, setStatusOptions] = useState([]);
    const [introducorOptions, setIntroducorOptions] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [documentSearch, setdocumentSearch] = useState(false);
    const [isSearchInputError, setIsSearchInputError] = useState(false);
    const [isDisplaySearchTip, setIsDisplaySearchTip] = useState(false);
    // same as onComponentMount
    useEffect(() => {
        if (masterData === undefined) {
            let temp = sessionStorage.getItem("masterData");
            //console.log("fire ==",temp)
            if (temp === null) {
                // then we will get masterData min first and then render.
                let minTemp = sessionStorage.getItem("masterDataMin");
                if (minTemp === null) {
                    // go server to get it.
                    searchBoxService.getSearchMaster().then(
                        (res) => {
                            if (res.status == 200 && res.data) {
                                setmasterData(res.data.masterData);
                                sessionStorage.setItem("masterData", JSON.stringify(res.data.masterData));
                                setIsLoading(false);
                                setTimeout(() => {
                                    //fetchAllMasterData();
                                }, 100);
                            } else {
                                errorAlert(res);
                                setIsLoading(false);
                            }
                        },
                        (error) => {
                            errorAlert(error);
                            setIsLoading(false);
                        }
                    );
                } else {
                    // then we have min master, render first, then get full master async.
                    setmasterData(JSON.parse(minTemp));
                    setIsLoading(false);
                    setTimeout(() => {
                        //fetchAllMasterData();
                    }, 100);
                }
            } else {
                setmasterData(JSON.parse(temp));
                setIsLoading(false);
            }
        } else {
        }
    }, []);

    useEffect(() => {
        generateOptions();
    }, [masterData]);

    // import data if exist.
    useEffect(() => {
        // when we do import data, also make sure to apply refined by infomation
        if (props.viewModel !== undefined && props.masterData !== undefined) {
            setmasterData(props.masterData);
            generateOptions();
            setSearchString(props.viewModel.searchString);
            setdocumentSearch(
                props.viewModel.documentSearch == "true" || props.viewModel.documentSearch == true ? true : false
            );
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        if (isSubmit === true) {
            handleSubmit();
        }
    }, [isSubmit]);

    useEffect(() => {
        importRefineBy(queryString);
    }, []);

    function importRefineBy(queryString) {
        let searchQuery = ((props.match || {}).params || {}).searchQuery;
        if (searchQuery != null) {
            let decodedObject = queryString.parse(props.match.params.searchQuery, queryConfig);
            if (decodedObject.legislationCategoryIdForSearch != null) {
                setLegislationCategoryId(decodedObject.legislationCategoryIdForSearch);
            }
        }
    }

    function generateOptions(input = undefined) {
        let master = undefined;
        if (input === undefined) {
            master = masterData;
        } else {
            master = input;
        }
        if (master !== undefined) {
            setCategoryOptions(
                master.legislationCategory != undefined
                    ? utility.getSingleSelectOptions(master.legislationCategory, "name", "name")
                    : []
            );
            setSubCategoryOptions(
                master.legislationSubCategory != undefined ? getSubOptions(master.legislationSubCategory) : []
            );
            setCouncilPeriodOptions(
                master.councilPeriodId != undefined ? utility.getSingleSelectOptions(master.councilPeriodId) : []
            );
            setRequestorOptions(master.requestor != undefined ? utility.getSingleSelectOptions(master.requestor) : []);
            setIntroducorOptions(
                master.masterDetailData != undefined
                    ? getIntroducerOptions(
                        master.masterDetailData[councilPeriodId === "" ? 0 : councilPeriodId].introducers
                    )
                    : []
            );
            setCommitteeOptions(
                master.masterDetailData != undefined
                    ? utility.getSingleSelectOptions(
                        master.masterDetailData[councilPeriodId === "" ? 0 : councilPeriodId].committees
                    )
                    : []
            );
            setStatusOptions(master.status != undefined ? utility.getSingleSelectOptions(master.status) : []);
        }
        function getSubOptions(masterData) {
            return masterData.map((item, index) => {
                return {
                    value: item.legislationCategoryTypeId,
                    text: item.name,
                    parentid: item.legislationCategoryTypeParentId,
                    key: index
                };
            });
        }
    }

    function handleSubmit() {
        setIsSubmit(false);
        // collect every thing needed and put into model, then upload.
        // validate the input string if not valid, display the error message.
        let result = validSearchInput(searchString);
        if (result != false) {
            let searchModel = {};
            searchModel.searchString = typeof searchString === "number" ? "" : searchString;
            if (legislationCategoryId != null && legislationCategoryId != "") {
                searchModel.legislationCategoryIdForSearch = legislationCategoryId;
            }
            searchModel.documentSearch = documentSearch;
            const urlString = queryString.stringify(searchModel, { arrayFormat: "index" });
            props.history.push(`/searchresult/${urlString}`);
        } else {
            setIsSearchInputError("Please restrict to one type of operator per search. Please refer to Help document for more details.");

        }
    }


    function validSearchInput(inputString) {
        let operatorsMap = {};
        inputString = inputString.toLowerCase();
        if (inputString == "") {
            return true;
        } else {
            let searchResultArray = inputString.split(" ");
            if (searchResultArray.length <= 3) {
                return true;
            } else {
                let myReg = /(\bnot|\band|\bor)/g;
                let searchResult = inputString.match(myReg);
                //console.log(searchResult);
                if (searchResult != null && searchResult.length > 1) {
                    // check is operators are same type
                    searchResult.forEach((item) => {
                        if (operatorsMap[item] == null) {
                            operatorsMap[item] = 1;
                        }
                        else {
                            operatorsMap[item] = operatorsMap[item] + 1;
                        }
                    });
                    if (Object.keys(operatorsMap).length > 1) {
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            }
            return false;
        }
    }

    function getIntroducerOptions(masterData) {
        let result = [];

        if (masterData === undefined || masterData.active === undefined || masterData.other === undefined) {
            result.push({
                key: -3,
                value: -1,
                text: "No Option Available.",
                disabled: true
            });
            return result;
        }

        if (masterData.active && masterData.active.length > 0) {
            if (masterData.other !== null) {
                result.push({
                    key: -2,
                    value: -1,
                    content: <h3>Active:</h3>,
                    disabled: true
                });
            }
            masterData.active.forEach((item, index) => {
                result.push({
                    key: `${item.name} + active + ${index}`,
                    value: item.id,
                    text: item.name
                });
            });
        }
        if (masterData.other && masterData.other.length > 0) {
            if (masterData.active !== null) {
                result.push({
                    key: -1,
                    value: -1,
                    content: <h3>Other:</h3>,
                    disabled: true
                });
            }
            masterData.other.forEach((item, index) => {
                result.push({
                    key: `${item.name}+other+${index}`,
                    value: item.id,
                    text: item.name
                });
            });
        }
        return result;
    }
    function errorAlert(errorMessage = "Error") {
        Swal.fire({
            type: "error",
            title: "Oops...",
            text: errorMessage,
            footer: "You may need check your Internet connection, or try again."
        });
    }
    function handleSkip(e) {
        if (e.key === "Enter" || e.key === " " || e.key === undefined) {
            let targetId = "search-result";
            let result = document.getElementById(targetId);
            if (result != null) {
                result.focus();
            }
        }

    }
    
    function handleInputChange() {
        if (isSearchInputError != false) {
            setIsSearchInputError(false);
        }
    }
    // render
    if (props.mode == "result" && (props.viewModel === undefined || props.masterData === undefined)) {
        return (
            <Segment basic style={{ margin: "0", padding: "0", minHeight: "499px" }}>
                <Dimmer active={isLoading}>
                    <Loader>Loading</Loader>
                </Dimmer>
            </Segment>
        );
    } else {
        return getRenderContent();
    }


    
    function getRenderContent() {
        const screenWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

        return (
            <Fragment>
                {/* <Segment>
				<h3>Test Info: showing errors</h3>
				{   
				<pre className="pre-word-wrap"><code>{JSON.stringify( documentSearch == true || documentSearch == "true" ? true : false, null, 4).replace(/([{},:])/g, ' $1 ')}</code></pre>
						}
				</Segment> */}
                <div className={props.mode !== "result" ? "no_print":""}>
                    <Segment basic style={{ margin: "0", padding: "0" }}>
                        <Dimmer active={isLoading}>
                            <Loader>Loading</Loader>
                        </Dimmer>
                        <div
                            className="intro__body"
                            style={
                                props.mode === "result"
                                    ? isDropdownOpen
                                        ? screenWidth >= 992
                                            ? { minHeight: "360px" }
                                            : screenWidth >= 767
                                                ? { minHeight: "200px" }
                                                : { minHeight: "150px" }
                                        : { minHeight: "80px" }
                                    : {}
                            }
                        >
                            <div className="form">
                                <form action="?" method="post">
                                    <div className="form__body">
                                        <div className="form__row">
                                            <div className="form__cols">
                                                <div className="form__col form__col--1of12">
                                                    {/* <label 
													id="search-label"
													htmlFor="field-search" className="form__label" style={{marginBottom:"10px"}}>
                                                        Number or Keyword
                                                    </label> */}
                                                    <Responsive maxWidth={767}>
                                                        <div className="form__controls ">
                                                            <div className="mobil-search-container">
                                                                <Dropdown
                                                                    style={{
                                                                        marginBottom: screenWidth < 767 ? "10px" : "5px",
                                                                    }}
                                                                    fluid
                                                                    onOpen={() => {
                                                                        setIsDropdownOpen(true);
                                                                    }}
                                                                    onClose={() => {
                                                                        setIsDropdownOpen(false);
                                                                    }}
                                                                    placeholder="All Categories"
                                                                    selection
                                                                    clearable
                                                                    selectOnBlur={false}
                                                                    options={categoryOptions}
                                                                    value={
                                                                        legislationCategoryId
                                                                            ? legislationCategoryId
                                                                            : null
                                                                    }
                                                                    onChange={(e, { value }) => {
                                                                        setLegislationCategoryId(value);
                                                                        setLegislationSubCategoryId(null);
                                                                    }}
                                                                />
                                                                <Input
                                                                    fluid
                                                                    id="field-search"
                                                                    autoFocus={false}
                                                                    value={searchString ? searchString : ""}
                                                                    aria-label="Search for legislation"
                                                                    placeholder="Search by keyword, legislation title, or legislation number"
                                                                    onKeyPress={(event) => {
                                                                        if (event.key == "Enter") {
                                                                            event.preventDefault();
                                                                            event.stopPropagation();
                                                                            handleSubmit();
                                                                        }
                                                                    }}
                                                                    action={
                                                                        <Fragment>
                                                                            {
                                                                                isSearchInputError !== false &&
                                                                                <Fragment>
                                                                                    <Popup
                                                                                        content={
                                                                                            <Message warning>
                                                                                                <Icon name="warning sign" color="orange" />
                                                                                                Please restrict to one type of operator per search. Please refer to Help document for more details.
                                                                                        </Message>
                                                                                        }
                                                                                        open flowing hoverable
                                                                                        position='top right'
                                                                                        trigger={
                                                                                            <Button
                                                                                                id="search-button"
                                                                                                type="button"
                                                                                                onClick={handleSubmit}
                                                                                                aria-label="search button"
                                                                                                value="search button"
                                                                                            >
                                                                                                <Icon
                                                                                                    name={"times circle outline"}
                                                                                                    color={"red"}
                                                                                                    alt="search button"
                                                                                                    aria-label="search button"
                                                                                                />
                                                                                            </Button>
                                                                                        }
                                                                                    />

                                                                                </Fragment>
                                                                            }
                                                                            {
                                                                                !(isSearchInputError !== false) &&
                                                                                <Button
                                                                                    id="search-button"
                                                                                    type="button"
                                                                                    onClick={handleSubmit}
                                                                                    aria-label="search button"
                                                                                    value="search button"
                                                                                >
                                                                                    <Icon
                                                                                        name={"search"}
                                                                                        alt="search button"
                                                                                        aria-label="search button"
                                                                                    />
                                                                                </Button>
                                                                            }
                                                                            {
                                                                                props.mode === "result" &&
                                                                                <div className="skip-to-main-div no_print">
                                                                                    <a className="skip-to-main-a" href="javascript:void(0)" onClick={
                                                                                        handleSkip
                                                                                    }>Skip to Search Result</a>
                                                                                </div>
                                                                            }

                                                                        </Fragment>
                                                                    }
                                                                    onChange={(e) => {
                                                                        setSearchString(e.target.value);
                                                                    }}
                                                                />
                                                                <div
                                                                    className="checkbox no_print"
                                                                    style={{ marginBottom: "0", width: "50%", float: "right", textAlign: "center", }}
                                                                >
                                                                    <input
                                                                        tabIndex={0}
                                                                        id="searchThroughDoc"
                                                                        name="searchThroughDoc"
                                                                        type="checkbox"
                                                                        checked={documentSearch == true ? true : false}
                                                                        onKeyPress={(e) => {
                                                                            e.preventDefault();
                                                                            e.stopPropagation();
                                                                            setdocumentSearch(!documentSearch);
                                                                        }}
                                                                    />
                                                                    <label
                                                                        htmlFor="searchThroughDoc"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            e.stopPropagation();
                                                                            setdocumentSearch(!documentSearch);
                                                                        }}
                                                                    >
                                                                        <span
                                                                            style={{
                                                                                display: "inline-block",
                                                                                position: "relative",
                                                                                top: "4px"
                                                                            }}
                                                                        >
                                                                            Search through documents
                                                                </span>
                                                                    </label>
                                                                </div>
                                                                {
                                                                    props.mode !== "result" &&
                                                                        <div className="checkbox back-home-div search-box-tooltip no_print" style={{ width: "125px" }}>
                                                                        <Link
                                                                        className="back-home-a"
                                                                        to="/SearchHelp"
                                                                        target = "_blank"
                                                                        title="Click to check search help document"
                                                                        >
                                                                        <Icon name="lightbulb outline" />
                                                                        <span style={{ color: "" }}>Search Tips</span>
                                                                        </Link>
                                                                        </div>
                                                                }
                                                                

                                                                {props.showBackToHome === true && (
                                                                    <div
                                                                        className="checkbox back-home-div"
                                                                    >
                                                                        <a
                                                                            className="back-home-a no_print"
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                props.history.push("/");
                                                                            }}
                                                                        >
                                                                            <Icon name="arrow left" />
                                                                            <span style={{ color: "" }}>Back to Home</span>
                                                                        </a>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Responsive>
                                                    <Responsive minWidth={768}>
                                                        <div className="form__controls ">
                                                            <label htmlFor="search_legislation_input" id="search_legislation_input_label">{isSearchInputError != false ? isSearchInputError : "type key words"}</label>
                                                            <Input
                                                                id="search_legislation_input"
                                                                error={isSearchInputError}
                                                                title={isSearchInputError != false ? isSearchInputError : "type key words"}
                                                                style={{ minHeight: "40px", fontSize: "1.0em" }}
                                                                fluid
                                                                autoFocus={true}
                                                                aria-label="Search for legislation"
                                                                label={
                                                                    <Dropdown
                                                                        htmlFor="search_legislation_input"
                                                                        selectOnBlur={false}
                                                                        style={{ minWidth: "330px" }}
                                                                        placeholder="All Categories"
                                                                        onOpen={() => {
                                                                            setIsDropdownOpen(true);
                                                                        }}
                                                                        onClose={() => {
                                                                            setIsDropdownOpen(false);
                                                                        }}
                                                                        selection
                                                                        clearable
                                                                        options={categoryOptions}
                                                                        value={
                                                                            legislationCategoryId
                                                                                ? legislationCategoryId
                                                                                : null
                                                                        }
                                                                        onChange={(e, { value }) => {
                                                                            setLegislationCategoryId(value);
                                                                            setLegislationSubCategoryId(null);
                                                                        }}
                                                                    />
                                                                }
                                                                labelPosition="left"
                                                                value={searchString ? searchString : ""}
                                                                placeholder="Search by keyword, legislation title, or legislation number"
                                                                action={
                                                                    <Fragment>
                                                                        {
                                                                            isSearchInputError !== false &&
                                                                            <Fragment>
                                                                                <Popup
                                                                                    content={
                                                                                        <Message warning>
                                                                                            <Icon name="warning sign" color="orange" />
                                                                                            Please restrict to one type of operator per search. Please refer to Help document for more details.
                                                                                        </Message>
                                                                                    }
                                                                                    open flowing hoverable
                                                                                    position='top right'
                                                                                    trigger={
                                                                                        <Button
                                                                                            id="search-button"
                                                                                            type="button"
                                                                                            onClick={handleSubmit}
                                                                                            aria-label="search button"
                                                                                            value="search button"
                                                                                        >
                                                                                            <Icon
                                                                                                name={"times circle outline"}
                                                                                                color={"red"}
                                                                                                alt="search button"
                                                                                                aria-label="search button"
                                                                                            />
                                                                                        </Button>
                                                                                    }
                                                                                />

                                                                            </Fragment>
                                                                        }
                                                                        {
                                                                            !(isSearchInputError !== false) &&
                                                                            <Button
                                                                                id="search-button"
                                                                                type="button"
                                                                                onClick={handleSubmit}
                                                                                aria-label="search button"
                                                                                value="search button"
                                                                            >
                                                                                <Icon
                                                                                    name={"search"}
                                                                                    alt="search button"
                                                                                    aria-label="search button"
                                                                                />
                                                                            </Button>
                                                                        }
                                                                        {
                                                                            props.mode === "result" &&
                                                                            <div className="skip-to-main-div no_print">
                                                                                <button className="skip-to-main-a" type="button"
                                                                                    aria-label="Skip to Search Result"
                                                                                    onClick={
                                                                                        handleSkip
                                                                                    }>Skip to Search Result</button>
                                                                            </div>
                                                                        }
                                                                    </Fragment>
                                                                }
                                                                onKeyPress={(event) => {
                                                                    if (event.key == "Enter") {
                                                                        event.preventDefault();
                                                                        event.stopPropagation();
                                                                        handleSubmit();
                                                                    }
                                                                }}
                                                                onChange={(e) => {
                                                                    setSearchString(e.target.value);
                                                                    handleInputChange();
                                                                }}
                                                            />
                                                        </div>

                                                        <div
                                                            className="checkbox no_print"
                                                            style={{ marginBottom: "0", width: "25%", float: "right", textAlign: "center", }}
                                                        >
                                                            <input
                                                                tabIndex={0}
                                                                id="searchThroughDoc"
                                                                name="searchThroughDoc"
                                                                type="checkbox"
                                                                onChange={() => { }}
                                                                checked={documentSearch == true ? true : false}
                                                                onKeyPress={(e) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    setdocumentSearch(!documentSearch);
                                                                }}
                                                            />
                                                            <label
                                                                htmlFor="searchThroughDoc"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    setdocumentSearch(!documentSearch);
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        display: "inline-block",
                                                                        position: "relative",
                                                                        top: "4px"
                                                                    }}
                                                                >
                                                                    Search through documents
                                                                </span>
                                                            </label>
                                                        </div>

                                                        <div
                                                            className="checkbox back-home-div search-box-tooltip no_print" style={{ width: "125px" }}
                                                        >
                                                            <Link
                                                                className="back-home-a"
                                                                to="/SearchHelp"
                                                                target = "_blank"
                                                                title="Click to check search help document"
                                                            >
                                                                <Icon name="lightbulb outline" />
                                                                <span style={{ color: "" }}>Search Tips</span>
                                                            </Link>
                                                        </div>

                                                        {props.showBackToHome === true && (
                                                            <div
                                                                className="checkbox back-home-div"
                                                            >
                                                                <a
                                                                    tabIndex={0}
                                                                    className="back-home-a no_print"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        props.history.push("/");
                                                                    }}
                                                                >
                                                                    <Icon name="arrow left" />
                                                                    <span style={{ color: "" }}>Back to Home</span>
                                                                </a>
                                                            </div>
                                                        )}
                                                    </Responsive>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Segment>
                </div>
            </Fragment>
        );
    }
}
