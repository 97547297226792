import React, { Component, Fragment, useState, useEffect, useRef } from 'react';
import { Dropdown, Icon, Dimmer, Loader, Segment, Grid, Input } from "semantic-ui-react";
import { searchBoxService } from "../../services/searchBoxService";
import Swal from 'sweetalert2';
import { utility } from "../../helpers/utility";
import { Link } from "react-router-dom";

export default function (props) {
	const [isLoading, setIsLoading] = useState(false);
	const [whatsNewData, setWhatsNewData] = useState(undefined);
	const [underConsiderationData, setUnderConsiderationData] = useState(undefined);
	const [currentTabIndex, setCurrentTabIndex] = useState(0);
	const [trendingData, setTrendingData] = useState([]);
	const [isLoadingWhatsNew, setIsLoadingWhatsNew] = useState(true);
	const [isLoadingUnderConsideration, setIsLoadingUnderConsideration] = useState(true);
	const [isLoadingTrending, setIsLoadingTrending] = useState(true);
	const [masterdata, setMasterData] = useState(undefined);
	const [options, setOptions] = useState([]);
	const [committee, setCommittee] = useState(0);

	useEffect(() => {

		searchBoxService.getWhatsNew().then(
			(res) => {
				if (res.status == 200 && res.data) {
					setWhatsNewData(res.data);
					if (Array.isArray(res.data === true && res.data.length === 0)) {
						// jump to display trending tab per requirment. if nothing to show here
						setCurrentTabIndex(1);
					}
					setIsLoadingWhatsNew(false);
				} else if (res.status == 200 || res.status == 204) {
					setWhatsNewData([]);
					// jump to display trending tab per requirment. if nothing to show here
					setCurrentTabIndex(1);
					setIsLoadingWhatsNew(false);
				}
				else {
					errorAlert(res.error);
					setIsLoadingWhatsNew(false);
				}
			},
			(error) => {
				errorAlert(error);
				setIsLoadingWhatsNew(false);
			}
		);

		searchBoxService.getUnderConsideration(committee).then(
			(res) => {
				if (res.status == 200 && res.data) {
					setUnderConsiderationData(res.data.billsUnderConsideration);
					setMasterData(res.data.masterData);
					let tempOptions = utility.getSingleSelectOptions(res.data.masterData);
					tempOptions.push({ value: 0, key: -1, text: "Select a committee..." });
					setOptions(tempOptions);
					setIsLoadingUnderConsideration(false);
				} else if (res.status == 204 || res.status == 200) {
					setUnderConsiderationData([]);
				}
				else {
					errorAlert(res.error);
					setIsLoadingUnderConsideration(false);
				}
			},
			(error) => {
				errorAlert(error);
				setIsLoadingUnderConsideration(false);
			}
		);
		searchBoxService.getTrending().then(
			(res) => {
				if (res.status == 200 && res.data) {
					setTrendingData(res.data);
					setIsLoadingTrending(false);
				} else
					if (res.status == 200 || res.status == 204) {
						setWhatsNewData([]);
						setIsLoadingWhatsNew(false);
					}
					else {
						errorAlert(res.error);
						setIsLoadingTrending(false);
					}
			},
			(error) => {
				errorAlert(error);
				setIsLoadingTrending(false);
			}
		);
	}, [committee]);

	useEffect(() => {
		if (whatsNewData !== undefined && underConsiderationData !== undefined) {
			setIsLoading(false);
		}
	}, [whatsNewData, underConsiderationData]);

	function errorAlert(errorMessage = "Error") {
		Swal.fire({
			type: 'error',
			title: 'Oops...',
			text: errorMessage,
			footer: 'You may need check your Internet connection, or try again.'
		})
	}
	return (
		<Fragment>
			<section className="section section--gray" style={{ padding: "35px 0 95px" }}>
				<div className="shell">
					<div className="section__body">
						<div className="section__cols">
							<div className="section__col section__col--1of2">
								<div className="section__head">
									<h1 tabIndex={0} style={{ fontSize: "2.0rem" }} className="section__title h2">What’s New</h1>
								</div>

								<div className="section__body">
									<div className="tabs">
										<div className="print_only"><h4>{currentTabIndex === 0 ? "Introduction" : "Trending"}</h4></div>
										<div className="tabs__head no_print">
											<nav className="tabs__nav" style={{ height: "50px" }}>
												<ul style={{ height: "100%" }}>
													<li className={currentTabIndex == 0 ? "current" : ""} style={{ height: "100%" }}>
														<a
															aria-label="Introductions tab"
															href="javascript:void(0)" style={{ paddingTop: "16px" }} onClick={() => { setCurrentTabIndex(0) }}>Introductions</a>
													</li>

													<li className={currentTabIndex == 1 ? "current" : ""} style={{ height: "100%" }}>
														<a
															aria-label="Trending tab"
															href="javascript:void(0)" style={{ paddingTop: "16px" }} onClick={() => { setCurrentTabIndex(1) }}>Trending</a>
													</li>
												</ul>
											</nav>
										</div>
										<div className="tabs__body">
											<div className={currentTabIndex === 0 ? "tab current translating-in" : "tab"} id="tab1">
												<div className="tab__content">
													<div className="posts" style={{ paddingLeft: 0 }}>
														<div className="posts__content">
															<Segment
																loading={isLoadingWhatsNew}
																basic style={{ margin: "0", padding: "0" }}>
																{
																	whatsNewData && whatsNewData.length > 0 &&
																	whatsNewData.map((item, index) => {
																		return <PostUnit
																			content={item}
																			key={index}
																		/>
																	})
																}
																{
																	(whatsNewData && whatsNewData.length == 0) &&
																	(<NoData key={`whatsNewData_${99}`} text="There were no Introductions in the last seven days." />)
																}

															</Segment>

														</div>
														{
															whatsNewData && whatsNewData.length > 4 &&
															<div className="posts__actions">
																<Link aria-label="Show more New Legislation"
																	onClick={() => { document.body.scrollTo(0, 0) }}
																	to="/searchresult/statisticType=6" className="btn-more">Show more</Link>
															</div>
														}
													</div>
												</div>

											</div>

											<div className={currentTabIndex === 1 ? "tab current translating-in" : "tab"} id="tab2">
												<div className="tab__content">
													<div className="posts" style={{ paddingLeft: 0 }}>
														<div className="posts__content">
															<Segment
																loading={isLoadingWhatsNew}
																basic style={{ margin: "0", padding: "0" }}>
																{
																	trendingData && trendingData.length > 0 &&
																	trendingData.map((item, index) => {
																		return <PostUnit
																			content={item}
																			key={index}
																		/>
																	})
																}
																{
																	(trendingData && trendingData.length == 0) &&
																	(<NoData key={`trendingData_${99}`} />)
																}
															</Segment>
														</div>
														<div className="posts__actions">&nbsp;</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="section__col section__col--1of2">
								<div className="section__head">
									<h1 tabIndex={0} className="section__title h2" style={{ fontSize: "2.0rem" }}
									>Upcoming Events</h1>
								</div>

								<div className="section__body">
									<div className="posts">
										<div className="posts__form">
											<span className="posts posts__form label committee-label" htmlFor="field-committee" style={{ color: "#000000", marginBottom: "0", border: "none", minHeight: "48px" }}>Committee </span>
											<div className="select-post js-select" style={{ width: "70%" }}>
												<div className="no_print">
													<Dropdown
														placeholder='Select a subcategory...'
														selection
														fluid
														id="field-committee"
														clearable={committee == 0 ? false : true}
														options={options}
														value={committee}
														onChange={(e, { value }) => {
															setCommittee(value ? value : 0);
														}}

													/>
												</div>
												<div className="print_only">
													{utility.getDropdownText(committee, options, "Any Committee")}
												</div>
											</div>
										</div>

										<div className="posts__content">
											<Segment
												loading={isLoadingUnderConsideration}
												basic style={{ margin: "0", padding: "0" }}>
												{
													underConsiderationData && underConsiderationData.length > 0 &&
													underConsiderationData.map((item, index) => {
														return <PostUnit
															content={item}
															key={index}
														/>
													})
												}
												{
													underConsiderationData && underConsiderationData.length == 0 &&
													(<NoData key={`underConsiderationData_${99}`}/>)
												}
											</Segment>
										</div>
										{
											(underConsiderationData && underConsiderationData.length > 4) &&
											<div className="posts__actions no_print">
												<Link to={`/upcominghearing/${committee || 0}`} onClick={() => { document.body.scrollTo(0, 0) }}
													aria-label="Show more items of Upcoming Events"
													className="btn-more">Show more</Link>
											</div>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Fragment>
	);

}


function NoData(props) {
	//console.log(props.content);
	return (
		<Fragment>
			<div className="post" style={{ minHeight: 110 }}>
				<h1 className="h5" style={{ verticalAlign: "middle", lineHeight: "80px"}}>
					<div>{props.text ? props.text : "No Records found"}</div>
				</h1>
			</div>
		</Fragment>
	);
}

function PostUnit(props) {
	//console.log(props.content);
	return (
		<Fragment>
			<div className="post" style={{ minHeight: 110 }}>
				<p className="post__meta no_print">
					<span style={{
						fontFamily: "Montserrat, Arial, Helvetica, sans-serif",
						fontSize: "15px",
						lineHeight: "22px",
						fontWeight: 400,
					}}>{props.content.legislationNumber}{props.content.legislationType ? <span style={{ fontStyle: "italic" }}>{` (${props.content.legislationType})`}</span> : ""}</span>
					{
						props.content.date != null &&
						<span className="post__date" style={{ marginRight: "20px", float: "right" }}>{utility.formatTime(props.content.date)}</span>
					}
				</p>
				<h1 className="post__title h5" style={{ lineHeight: "1.5" }}>
					{
						(props.content.legislationId || props.content.legislationNumber) &&
						<div>
							{props.content.legislationNumber &&
								<Link
									to={`/Legislation/${props.content.legislationNumber}`}>{props.content.title}</Link>
							}
							{
								!props.content.legislationNumber &&
								<span>{props.content.title}</span>
							}
						</div>

					}
					{
						!(props.content.legislationId || props.content.legislationNumber) && <span>{props.content.title}</span>
					}
				</h1>

			</div>
		</Fragment>
	);
}