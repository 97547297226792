export const config = {
    apiUrl: "/api",
}

export const queryConfig = {
    arrayFormat: 'index',
}

export const statisticMap = {
    1:"LawSearch",
    2:"EmergencyActsSearch",
    3:"LegislationSearch",
    4:"MayoralSignatureSearch",
    5:"UnderCongressionalReviewSearch",
    6: "WhatsNewSearch",
}