import React, { Component, Fragment, useState, useEffect, useRef} from 'react';
import { searchBoxService } from "../../services/searchBoxService";
import { Dropdown, Icon, Dimmer, Loader, Segment, Grid, Input } from "semantic-ui-react";
import Swal from 'sweetalert2';
import _ from "lodash";
import FlipNumbers from 'react-flip-numbers';
import { utility } from '../../helpers/utility';

export default function (props) {
	// import in require way.
	const queryString = require('query-string');

	const dummyOptions = [{
		key: -1,
		text: "No option available",
		value: 0,
	}];

	const [testNumber, setTestNumber] = useState(0);
	const testNumber2 = useRef(0)
	const clockIcon = <svg
		xmlns="http://www.w3.org/2000/svg"
		width="64"
		height="64"
		version="1.1"
		viewBox="0 0 64 64"
	>
		<g transform="translate(0 -280.067)">
			<ellipse
				cx="31.446"
				cy="313.995"
				fill="#358ee0"
				fillOpacity="1"
				strokeWidth="1.508"
				rx="30.285"
				ry="30.03"
			></ellipse>
			<ellipse
				cx="31.568"
				cy="314.108"
				fill="#fff"
				strokeWidth="1.418"
				rx="28.392"
				ry="28.341"
			></ellipse>
			<path
				fill="#358ee0"
				fillOpacity="1"
				strokeWidth="0.983"
				d="M31.068 287.067H33V289.067H31.068z"
			></path>
			<path
				fill="#358ee0"
				fillOpacity="1"
				strokeWidth="1"
				d="M4 313.067H6V315.067H4z"
			></path>
			<path
				fill="#358ee0"
				fillOpacity="1"
				strokeWidth="1.017"
				d="M31 301.067H33V315.545H31z"
			></path>
			<circle
				cx="32.192"
				cy="314.105"
				r="2.199"
				fill="#358ee0"
				fillOpacity="1"
				strokeWidth="1.466"
			></circle>
			<path
				fill="#358ee0"
				fillOpacity="1"
				strokeWidth="1.414"
				d="M28 281.067H36V283.067H28z"
			></path>
			<path
				fill="#358ee0"
				fillOpacity="1"
				strokeWidth="0.983"
				d="M57 313.067H58.932V315.067H57z"
			></path>
			<path
				fill="#358ee0"
				fillOpacity="1"
				strokeWidth="0.983"
				d="M31.068 339.067H33V341.067H31.068z"
			></path>
			<path
				fill="#358ee0"
				fillOpacity="1"
				strokeWidth="1.336"
				d="M243.999 176.117H245.999V201.117H243.999z"
				transform="rotate(45)"
			></path>
		</g>
	</svg>;

	const certificateIcon = <svg
		xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="title" aria-describedby="desc"
		role="img">
		<path data-name="layer2" d="M50 46V10a8 8 0 0 0-8-8H9.2c4.4 0 6.8 3.6 6.8 8v44a8 8 0 0 0 8 8"
			fill="none" stroke="#358ee0" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
		<path data-name="layer2" d="M54 46H24a8 8 0 0 1 0 16h30a8 8 0 0 0 0-16zM9.2 2C4.8 2 2 5.6 2 10h14"
			fill="none" stroke="#358ee0" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
		<path data-name="layer1" fill="none" stroke="#358ee0" stroke-linecap="round" stroke-linejoin="round"
			stroke-width="2" d="M26 14h14M26 24h14M26 34h14"></path>
	</svg>;
	
	const [statisticsData, setStatisticsData] = useState(undefined);
	const [isLoading, setIsLoading] = useState(true);
	const [masterData, setMasterData] = useState(() => { return JSON.parse(sessionStorage.getItem('masterData')) })
	const [options, setOptions] = useState(() => { return dummyOptions });
	const [councilPeriodId, setCouncilPeriodId] = useState(0);
	useEffect(() => {
		if (!(statisticsData != null)) {
			fetchData(0);
		} else if (councilPeriodId !== statisticsData.councilPeriodId) {
			fetchData(councilPeriodId);
		}

	}, [councilPeriodId]);

	useEffect(() => {
		//getMaster(500);
	}, []);

	function fetchData(councilPeriodId) {
		searchBoxService.getStatistics(councilPeriodId).then(
			(res) => {
				if (res.status == 200 && res.data) {
					setStatisticsData(res.data);
					if (res.data.councilPeriodId != null) {
						setCouncilPeriodId(res.data.councilPeriodId);
					}
					setIsLoading(false);
				} else {
					errorAlert(res.error);
					setIsLoading(false);
				}
			},
			(error) => {
				errorAlert(error);
				setIsLoading(false);
			}
		);
	}

	function getMaster(timeDelay) {
		let newMaster = JSON.parse(sessionStorage.getItem('masterData'));
		if (!(newMaster != null)) {
			setTimeout(() => {
				getMaster(timeDelay);
			}, timeDelay);
		} else {
			setMasterData(newMaster);
			//setOptions(generateOptions(newMaster.councilPeriodId));
		}
	}

	function errorAlert(errorMessage = "Error") {
		Swal.fire({
			type: 'error',
			title: 'Oops...',
			text: errorMessage,
			footer: 'You may need check your Internet connection, or try again.'
		})
	}

	function updateTimer() {
		if(testNumber2.current < 50){
			testNumber2.current = testNumber2.current + 1;
		}
		setTestNumber(new Date().valueOf());
	}

	function startTimer() {
		//setInterval(updateTimer,100);
	}
	

	useEffect(
		startTimer
	, []);
	{
		let numbers = [" "," "," "," "," "];
		if(statisticsData != null){
			statisticsData.summary.forEach((item,index)=>{
				numbers[index] = item.count;
			});
		}

		return (
			<Fragment>
				<section className="section section--inkblue number_displayer" style={{ paddingTop: "20px" }}>
					<div className="shell" style={{ paddingLeft: "0", paddingRight: "0" }}>
						<div className="section__head">
							<h1 className="section__title h2 council_period_header">Council Period<span>
								{" "}
							</span>
								{`${( ((statisticsData||{}).councilPeriodDates) || "" )}`}</h1>
						</div>
						<div className="section__body no_print">
							<div className="services">
								<div className="service left1" onClick={() => {
									props.history.push(`/searchresult/councilPeriodId=${councilPeriodId}&statisticType=3`);
								}} >
									<div className="service__image">
										<span className="ico-checkbox">
											{ certificateIcon }
											{/*<svg role="presentation" aria-hidden={true} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-label="Resolutions icon image" alt="Resolutions icon image"><path data-name="layer2" fill="none" stroke="#358ee0" strokeMiterlimit="10" strokeWidth="2" d="M15.74 30l12 14 34-42" strokeLinejoin="round" strokeLinecap="round"></path><path data-name="layer1" d="M53.443 32A26.002 26.002 0 1 1 27.75 10a25.914 25.914 0 0 1 10 1.993" fill="none" stroke="#358ee0" strokeMiterlimit="10" strokeWidth="2" strokeLinejoin="round" strokeLinecap="round"></path></svg>*/}
										</span>
									</div>

									<div className="service__content">
										{/* <FlipNumbers height={36} width={24} color="black" background="white" play perspective={500} numbers={`${numbers[4]}`} /> */}
										<span>{`${numbers[4]}`}</span>
										<h1 className="h6">Introductions</h1>
									</div>
								</div>
								<div className="service left2" onClick={() => {

									props.history.push(`/searchresult/councilPeriodId=${councilPeriodId}&statisticType=1`);
								}} >
									<div className="service__image">
										<span className="ico-hammer">
											<svg role="presentation" aria-hidden={true} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-label="laws icon image" alt="laws icon image"><path data-name="layer1" fill="none" stroke="#358ee0" strokeMiterlimit="10" strokeWidth="2" d="M36 53h26v8H36z" strokeLinejoin="round" strokeLinecap="round"></path><path data-name="layer2" fill="none" stroke="#358ee0" strokeMiterlimit="10" strokeWidth="2" d="M16.93 14.29l9.9-9.9 18.385 18.385-9.9 9.899zM28.2 3L15.4 15.7m31.2 5.7L33.8 34.1M26 23.5l-24 24" strokeLinejoin="round" strokeLinecap="round"></path><path data-name="layer1" fill="none" stroke="#358ee0" strokeMiterlimit="10" strokeWidth="2" d="M38 47h22" strokeLinejoin="round" strokeLinecap="round"></path></svg>
										</span>
									</div>

									<div className="service__content" >
										{/* <FlipNumbers height={36} width={24} color="black" background="white" play perspective={500} numbers={`${numbers[0]}`} /> */}
										<span>{`${numbers[0]}`}</span>
										<h1 className="h6">Laws</h1>
									</div>
								</div>

								<div className="service" onClick={() => {
									props.history.push(`/searchresult/councilPeriodId=${councilPeriodId}&statisticType=2`);
								}} >
									<div className="service__image">
										<span className="ico-siren">
											{clockIcon}
											{/* <svg role="presentation" aria-hidden = {true}  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-label="Emergency Acts icon image" alt = "Emergency Acts icon image"><circle data-name="layer2" cx="32" cy="40" r="3" fill="none" stroke="#358ee0" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" strokeLinejoin="round"></circle><path data-name="layer2" fill="none" stroke="#358ee0" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" d="M32 43v13M2 15l9 9m9-21l4 12m38 0l-9 9M44 3l-4 12" strokeLinejoin="round"></path><path data-name="layer1" d="M8 56h48a6 6 0 0 1 6 6H2a6 6 0 0 1 6-6zm4 0V40a20.059 20.059 0 0 1 20-20 20.059 20.059 0 0 1 20 20v16" fill="none" stroke="#358ee0" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" strokeLinejoin="round"></path></svg> */}
										</span>
									</div>
									<div className="service__content">
										{/* <FlipNumbers height={36} width={24} color="black" background="white" play perspective={500} numbers={`${numbers[1]}`} /> */}
										<span>{`${numbers[1]}`}</span>
										<h1 className="h6">Emergency Acts</h1>
									</div>
								</div>

								<div className="service right1" onClick={() => {
									props.history.push(`/searchresult/councilPeriodId=${councilPeriodId}&statisticType=4`);
								}} >
									<div className="service__image">
										<span className="ico-inkpot">
											<svg role="presentation" aria-hidden={true} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-label="Awaiting Mayor’s Action icon image" alt="Awaiting Mayor’s Action icon image"><path data-name="layer2" d="M48 4.7C41.1 8 34 15.1 34 30c12 0 28-2.3 28-28L29.9 34.1M48 4.7V16m-.1 0h11.9M42 22h14.5" fill="none" stroke="#358ee0" strokeMiterlimit="10" strokeWidth="2" strokeLinejoin="round" strokeLinecap="round"></path><path data-name="layer1" d="M24 46.5V43a1 1 0 0 1 1-1h1a1.1 1.1 0 0 0 1-1v-4a1 1 0 0 0-1-1H14a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h1a1 1 0 0 1 1 1v3.5A18.6 18.6 0 0 0 2 62h36a18.6 18.6 0 0 0-14-15.5z" fill="none" stroke="#358ee0" strokeMiterlimit="10" strokeWidth="2" strokeLinejoin="round" strokeLinecap="round"></path></svg>
										</span>
									</div>

									<div className="service__content">
									{/* <FlipNumbers height={36} width={24} color="black" background="white" play perspective={500} numbers={`${numbers[2]}`} /> */}
									<span>{`${numbers[2]}`}</span>
										<h1 className="h6">Awaiting Mayor’s Action</h1>
									</div>
								</div>

								<div className="service right2" onClick={() => {
									props.history.push(`/searchresult/councilPeriodId=${councilPeriodId}&statisticType=5`);
								}} >
									<div className="service__image">
										<span className="ico-guide">
											<svg role="presentation" aria-hidden={true} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-label="Under Congressional Review icon image" alt="Under Congressional Review icon image"><path data-name="layer2" d="M24 52a8 8 0 0 1 8 8V16a8 8 0 0 0-8-8H2v44zM54 8h8v44H40a8 8 0 0 0-8 8" fill="none" stroke="#358ee0" strokeMiterlimit="10" strokeWidth="2" strokeLinejoin="round" strokeLinecap="round"></path><path data-name="layer2" d="M32 16a8 8 0 0 1 8-8h2" fill="none" stroke="#358ee0" strokeMiterlimit="10" strokeWidth="2" strokeLinejoin="round" strokeLinecap="round"></path><path data-name="layer1" fill="none" stroke="#358ee0" strokeMiterlimit="10" strokeWidth="2" d="M54 26l-6-4-6 4V4h12v22zm-44-8h14m-14 8h14m-14 8h14m-14 8h14m16-8h14m-14 8h14" strokeLinejoin="round" strokeLinecap="round"></path></svg>
										</span>
									</div>

									<div className="service__content">
									{/* <FlipNumbers height={36} width={24} color="black" background="white" play perspective={500} numbers={`${numbers[3]}`} /> */}
									<span>{`${numbers[3]}`}</span>
										<h1 className="h6">Under Congressional Review</h1>
									</div>
								</div>								
							</div>
						</div>
						<div className = "section__body print_only">
							<section>
								<ul>
									<li><h1 className="h6">{numbers[0]} Laws</h1></li>
									<li><h1 className="h6">{numbers[1]} Emergency Acts</h1></li>
									<li><h1 className="h6">{numbers[2]} Awaiting Mayor’s Action</h1></li>
									<li><h1 className="h6">{numbers[3]} Under Congressional Review</h1></li>
									<li><h1 className="h6">{numbers[4]} Resolutions</h1></li>
								</ul>
							</section>
						</div>
					</div>
				</section>

			</Fragment>
		);
	}


}