
import React, { lazy, Suspense, useEffect } from "react";
import { MemoryRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { Dropdown, Icon, Dimmer, Loader, Segment, Grid, Input } from "semantic-ui-react";
import { Layout } from './components/layout/Layout';
import { Home } from './components/home/Home';
import "./css/bundle.css";
import axios from 'axios';

const SearchResult = lazy(() => import('./components/searchResult/SearchResult'));
const ErrorPage = lazy(() => import('./components/error/ErrorPage'));
const LegislationDetail = lazy(() => import('./components/legislationDetails/LegislationDetail'));
const IntroducerDetail = lazy(() => import('./components/IntroducerDetail/introducerDetail.page'));
const DeveloperRegistration = lazy(() => import('./components/developerRegitration/developerRegistration.page'));
const VoteSearch = lazy(() => import('./components/voteSearch/VoteSearch'));
const UpcomingHearing = lazy(() => import('./components/upcomingHearing/UpcomingHearing.page'));
const SearchHelp = lazy(() => import('./components/searchHelp/SearchHelp'));
const APIDocument = lazy(() => import('./components/APIDocument/APIDocument'));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUpdatePopupOpen: false,
    };
    this.previousPathname = window.location.pathname;
    this.localStorageKey = 'LAST_MODIFIED';
    this.checkVersionUpdate = this._checkVersionUpdate.bind(this);
  }

  componentDidMount(){
    this.checkVersionUpdate();
    document.documentElement.addEventListener('click', this.checkLocationChange);
};
componentWillUnmount(){
    document.documentElement.removeEventListener('click', this.checkLocationChange);
}

  closeRefreshAlert = () => {
    this.setState({
      isUpdatePopupOpen: false,
    });
  }
  openRefreshAlert = () => {
    this.setState({
      isUpdatePopupOpen: true,
    });
  }
  checkLocationChange = (e) => {

    const newPathname = window.location.pathname;
    if (this.previousPathname !== newPathname && e.target.tagName == 'A') {
      this.previousPathname = newPathname;
      this.checkVersionUpdate();
    }
  }
  _checkVersionUpdate(){
    console.log('Checking update...');
    const defaultBaseURL = window.location.protocol + '//' + window.location.host;

    let newInstance = axios.create({
        baseURL: defaultBaseURL,
        timeout: 1000,
        crossDomain: true,
        headers: {'Accept': '*/*',
        'Cache-Control': 'no-cache',
      }});
    newInstance
    .get(`/manifast.json`)
    .then(response => {
        //console.log(JSON.stringify(response));
        //console.log((response||{}).headers);
        let { headers } = response||{};
        let newLastModifiedTime = headers['last-modified'] || '';
        if(newLastModifiedTime){
        const storedLastModified  = localStorage.getItem(this.localStorageKey);
        if (storedLastModified == null || storedLastModified == '') {
            localStorage.setItem(this.localStorageKey, newLastModifiedTime);
        } else if (storedLastModified !== newLastModifiedTime) {
            this.openRefreshAlert();
        }
        }

    } )
    .catch(async (error) => {
        // handle fail
        console.log(JSON.stringify(error));
    });

}
  render() {
    return (
      <Layout>
        <Switch>
          <Route exact path='/share/Legislation/:legislationNumber' component={WaitingComponent(LegislationDetail)} />
          <Route exact path='/share/media/Legislation/:legislationNumber' component={WaitingComponent(LegislationDetail)} />
          <Route exact path='/api/search/GetLegislationMetadata/:legislationNumber' component={WaitingComponent(LegislationDetail)} />
          <Route exact path='/efiling' component={RedirectToEfiling} />
          {/* <Route exact path='/efiling' component={RedirectToEfiling} /> */}
          <Route exact path='/efiling/*' component={RedirectToEfiling} />
          <Route exact path='/searchresult/:searchQuery?' component={WaitingComponent(SearchResult)} />
          <Route exact path='/Legislation/:legislationNumber' component={WaitingComponent(LegislationDetail)} />
          <Route exact path='/introducerDetail/:introducerTermId/:councilPeriod' component={WaitingComponent(IntroducerDetail)} />
          <Route exact path='/upcomingHearing/:committeeId' component={WaitingComponent(UpcomingHearing)} />
          <Route exact path='/developerRegistration' component={WaitingComponent(DeveloperRegistration)} />
          <Route exact path='/VoteSearch' component={WaitingComponent(VoteSearch)} />
          <Route exact path='/SearchHelp' component={WaitingComponent(SearchHelp)} />
          <Route exact path='/api/APIDocument' component={WaitingComponent(APIDocument)} />
          <Route exact path='/#' component={Home} />
          <Route exact path='/home' component={Home} />
          <Route exact path='/' component={Home} />
          <Route component={WaitingComponent(ErrorPage)} />
        </Switch>
        <RefreshAlert 
            isOpen = {this.state.isUpdatePopupOpen}
            closeRefreshAlert = {this.closeRefreshAlert}
            localStorageKey={this.localStorageKey}
        />
      </Layout>
    );
  }
}
export default App;

function WaitingComponent(Component) {
  return props => (
    <Suspense fallback={<div>
      <Segment basic style={{ minHeight: "800px", padding: "0", margin: "0" }}>
        <Dimmer active inverted>
          <Loader inverted>Loading...</Loader>
        </Dimmer>
      </Segment>
    </div>}>
      <Component {...props} />
    </Suspense>
  );
}

function RedirectToEfiling() {
  //console.log(window.location.href.split('/efiling/'));
  let temp = window.location.href.split('/efiling/');
  let appended = '';
  if (temp.length === 2) {
    appended = window.location.href.split('/efiling/')[1];
  }

  //console.log(appended);
  //console.log(`${process.env.REACT_APP_EFILING_URL}/${appended}`)


  window.location.href = `${process.env.REACT_APP_EFILING_URL}/${appended}`;
  return null;
}

function RefreshAlert(props){
  if(!props.isOpen){
      return <div></div>;
  }else {
      return (<div 
          style={{position:'fixed',zIndex:9999, bottom:20, right:20,
          width:350,height:130,
          borderRadius:'10px',
          border:'1px solid #4bd6b1', backgroundColor:'#d2f5eb', padding:14}}>
              <Icon name='close' id='app-refresh-close-icon' title='close this notification' onClick={()=>{props.closeRefreshAlert();}} style={{marginRight:'6px',fontSize:'14px'}}/>
              <p>A new and improved version of the application is now available.</p>
              <button id='app-refresh-button'
              type='button'
              onClick={(e)=>{
                  e.preventDefault();
                  e.stopPropagation();
                  localStorage.removeItem(props.localStorageKey);
                  window.location.reload(true);
              }}><Icon name='redo' style={{marginRight:'6px',fontSize:'14px'}}/>Refresh Now</button>
          </div>);
  }
}