import axios from 'axios';
import swal from 'sweetalert2';

export const axioService = {
    getAxioInstance,
};

function getAxioInstance(type=undefined) {   
    let params = {};
    const Toast = swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000
      });
      
      
    if(type === "blob"){
        params.responseType = "blob";
    }
    let instance = axios.create(params); 
    
    //when request initiate
    instance.interceptors.request.use(function (config) {
        // Do something before request is sent              
        return config;
    }, function (error) {
        // Do something with request error
        return Promise.reject(error);
    });

    //When resoponse received from the server
    instance.interceptors.response.use(response => {
        // Do something with response data
        return response;
    }, error => {
        // Do something with response error
        console.log("Network Error:",error);
        Toast.fire({
            type: 'error',
            title: 'Internal Server Error - ',
            text: 'We encountered a technical issue.',
          })
        return Promise.reject(error);
    });
    return instance;   
}
