import React, { Component, Fragment, useState, useRef, useEffect } from 'react';
import { Responsive, Menu, Icon, Dropdown, Ref, Sticky } from "semantic-ui-react";
import { Route, Redirect } from 'react-router-dom';
import Footer from "./Footer";
import Header from "./Header";

export function Layout(props) {
    const [titleText, setTitleText] = useState(document.title);
    const [currentURL, setCurrentURL] = useState(window.location.href);
    
    function updateProps(newTitle = null){
        setTitleText(newTitle || document.title);
        setCurrentURL(window.location.href);
        let legislationIndex = window.location.href.toLowerCase().indexOf('/legislation/');
        if (legislationIndex != -1) {
            //http://host:port/share/Legislation/{legislationNumber}
            let lowercaseURL = window.location.href.toLowerCase();
            let alteredMediaURL = lowercaseURL.replace('/legislation/', '/share/legislation/');
            setCurrentURL(alteredMediaURL);
        }
    }
    return (
        <Fragment>
            <div className="wrapper">
                <div className="sticky no_print" onMouseEnter={()=>{updateProps()}}>
                    <div data-network="twitter" className="st-custom-button"
                        data-url={currentURL}
                        data-title = {titleText}
                    ><Icon name="twitter" /><span>Tweet</span></div>
                    <div data-network="facebook"
                        data-url={currentURL}
                        data-title = {titleText}
                        className="st-custom-button"><Icon name="facebook official" /><span>Share</span></div>
                    <div data-network="email"
                        data-url={currentURL}
                        data-title = {titleText}
                        className="st-custom-button"><Icon name="mail" /><span>Email</span></div>
                    <div data-network="linkedin"
                        data-url={currentURL}
                        data-title = {titleText}
                        className="st-custom-button"><Icon name="linkedin" /><span>Share</span></div>
                </div>
                <Header className="no_print"></Header>
                    {props.children}
                <Footer className="no_print"></Footer>
            </div>
        </Fragment>
    );
}
