import _ from 'lodash';
import moment from 'moment';
import swal from "sweetalert2";

export const utility = {
    getSingleSelectOptions,
    getPaginationArray,
    formatTime,
    handleError,
    saveState,
    loadState,
    reformatDate,
    payloadCleaner,
    sanitizeHTML,
    getDropdownText,
    isInternetExplorer,
    renameKeysForStyle
}


function isInternetExplorer() {

    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer, return version number
    {
        //parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)))
        return true;
    }

    return false;
}

function handleError(errorObj,message = null) {
  console.log(JSON.stringify(errorObj));
  
}

function getSingleSelectOptions(masterDataArray,idName="id",valueName = "name",filterFun = (item)=>{return true;}) {
    
    let result = null;
    if( masterDataArray && (typeof masterDataArray === "object")  && masterDataArray.length !== undefined){
        if(typeof masterDataArray[0] === "object"){
            result =  masterDataArray.filter(filterFun).map((item,index)=>{
                return {
                    key : index,
                    value : item[idName],
                    text : item[valueName],
                }
            });
        }else {
            result = masterDataArray.map((item,index)=>{
                return {
                    key : index,
                    value : item,
                    text : item,
                }
            });
        }
        
    }
    else {
        result = [];
    }
    if(result.length ==0){
        result.push({
            key:-1,
            value:-1,
            text:"No Option Available.",
            disabled:true,
        });
    }
    return result;
}

function getPaginationArray(total,activeNumber,showLength=1,marginLength = 0){
    // this method will retrun an array that represent what should be showed in the pagination
    // example: total : 10. show count = 2 active number = 5 output:[3,4,5,6,7]
    if(total > 0){
      let left = activeNumber-showLength >= 1 ? activeNumber-showLength : 1;
      let right = activeNumber+showLength <=total ? activeNumber+showLength : total;
      if(right-activeNumber < showLength){
        // can expend on left
        let remain = showLength-(right-activeNumber);
        left = left-remain >=1 ? left-remain : 1;
      }else if(activeNumber-left < showLength){
        // can expend on right
        let remain = showLength-(activeNumber-left);
        right = right + remain <=total ? right + remain : total;
      }
      
      // expend left if not reached 0
      let leftExtend = [];
      if(left >  ( marginLength + 2) ){
        leftExtend = _.range(1,Math.min(marginLength + 2,left));
        leftExtend.push("...");
      }else if(left >=  (marginLength + 1 )){
        leftExtend = _.range(1,Math.min(marginLength + 2,left));
      }
      let rightExtend = [];
      if(  right < total - marginLength -1 ){
        rightExtend.push("...");
        rightExtend = rightExtend.concat(_.range(Math.max(total - marginLength,right),total + 1));
      }else if(right >= total - marginLength -1 && right < total){
        rightExtend = (_.range(Math.max(total - marginLength,right + 1),total + 1));
       
      }
      let result =leftExtend.concat(_.range(left, right+1)).concat(rightExtend); 
      return result;
      
    }else {
      return [];
    }
  }

  function formatTime(time,format="MMM DD, YYYY") {
    // sanity check
    if(time == null || time == "0001-01-01T00:00:00" || time == ""){
      return "";
    }
    // parse time and output formated string
    // Mode list: see https://momentjs.com/

    let tempTime;
    try {
      tempTime = moment(time);  
    } catch (error) {
      console.log("Invalid time input! ",error);
      throw "Invalid time input!";   
    }
    return tempTime.format(format);
    
  }
  
  function saveState(fieldName,state) {
    let codedStr = JSON.stringify(state);
    sessionStorage.setItem(fieldName,codedStr);   
  }

  function loadState(fieldName) {
    let codedStr = sessionStorage.getItem(fieldName);
    if(codedStr != null){
      return JSON.parse(codedStr);
    }
    else {
      return null;
    }
    
  }

  function reformatDate(input) {
    let output = input;
        if(input && typeof input === "string" && input.length > 0){
            let reg = /{{.*?}}/g;
            let result = "start";
            while (result != null) {
                result = reg.exec(input);
                if(result !== null){
                    if(result.length > 0){
                        result.forEach((item,index)=>{
                            let dateString = item;           
                            dateString = dateString.replace("{{","");
                            dateString = dateString.replace("}}","");
                            dateString = utility.formatTime(dateString);
                            output = output.replace(item,dateString);
                        });
                    }
                }    
            }
            return output;
        }else {
            return input;
        }
}

function payloadCleaner(targetObj,type=null){
    switch (type) {
        case "voteSearch":
            let result = _.cloneDeep(targetObj);
            delete result.councilPeriod;
            delete result.voteType;
            delete result.voteChoice;
            delete result.voteResult;
            delete result.councilMember
            delete result.councilMemberVotingSearchViewEntityData.dataTableData;
            return result;
        default:
            return targetObj;
    }
}

function getFlatenObject(targetObj,ignorNames=[], maxDeepth = 99, parentName = null,rootObj = null  ) {
  if(maxDeepth != null && maxDeepth <= 0){
      if(rootObj != null && parentName != null){
          rootObj[parentName] = _.cloneDeep(targetObj);
          return null;
      }
      else {
          return targetObj;
      }
  
  }
  if( typeof targetObj !== "object"){
      if(rootObj != null && parentName != null){
          rootObj[parentName] = _.cloneDeep(targetObj);
          return null;
      }
      else {
          return targetObj;
      }
  }
  if ( !(targetObj != null) ){
      if(rootObj != null && parentName != null){
          rootObj[parentName] = _.cloneDeep(targetObj);
          return null;
      }
      else {
          return targetObj;
      }
  }

  // now input is an object, maybe array, or object.
  let result = {};
  if(rootObj != null){result = rootObj};
  // consider is this an object or an array
  if(targetObj.length != null ){
      // arrayItem
      if(targetObj.length >0){
          targetObj.forEach((item,index)=>{
              let currentObject = item;
                  let type = getTypeofString(currentObject);
                  let peropertyName = parentName != null ? `${parentName}.${index}` : index;
                  if(type !== "object" && type !== "array" && hasHit(currentObject,ignorNames) !== true){
                      result[peropertyName] = currentObject;
                  }else {
                      getFlatenObject(currentObject,ignorNames, maxDeepth != null ? maxDeepth-1 : null, parentName != null ? `${parentName}.${index}` : index,result );
                  }
          });
      }else {
         if(parentName != null){
              rootObj[parentName] = targetObj;
         }else {
             return targetObj;
         }
      }
  
  }else {
      // object
      let keys = Object.keys(targetObj);
      if(keys.length > 0){
          keys.forEach((key)=>{
              // consider different type of object
              // object, array, primitive value, ignoredKeys, function, null
              let currentObject = targetObj[key];
              let type = getTypeofString(currentObject);
              let peropertyName = parentName != null ? `${parentName}.${key}` : key;
          
              if(hasHit(key,ignorNames)){
                  result[peropertyName] = currentObject;
              }else {
                  if( !(hasHit(key,ignorNames) !== true && type !== "object" && type !== "array")){
                      getFlatenObject(currentObject,ignorNames, maxDeepth != null ? maxDeepth-1 : null, parentName != null ? `${parentName}.${key}` : key,result );
                  }else {
                      result[peropertyName] = currentObject;
                  }
              }
          
          });
      }else {
          if(parentName != null){
              rootObj[parentName] = targetObj;
         }else {
             return targetObj;
         }
      }

  
  }
  return result;

  function hasHit(keyword,checkList = []) {
      if(checkList.filter((item)=>{
          return item === keyword;
      }).length !== 0){
          return true;
      }else {
          return false;
      }
  }

  function getTypeofString(target) {
      if(target === undefined){
          return "undefined";
      }else if(target === null){
          return "null";
      }else if(typeof target === "function"){
          return "function";
      }else if(typeof target === "object"){
          if(target.length != null){
              return "array";
          }else {
              return "object";
          }
      }else {
          return typeof target;
      }
  }

}

function unFlatenObject(target) {
  let result = {};
  if(typeof target !== "object" || !(target != null)){
      return target;
  }
  let keys = Object.keys(target);
  if(keys.length > 0){
      keys.forEach((key,index)=>{
          let levels = key.split(".");
          let value = target[key];
          if(levels.length === 1){
              // top level property, just copy it.
              result[key] = target[key];
          }else {
              // nested property, reconstruct the structure, then assgin value
              let parent = result;
              for(let i=0;i<levels.length;i++){
                  let currentKey = levels[i];
                  // not an index, an object key
                  if(parent[currentKey] != null){
                      if (i === levels.length -1) {
                          parent[currentKey] = value;
                      }else {
                          parent = parent[currentKey];
                      }
                  }else {
                      //current level do not exist, or index not exist.
                      if (i === levels.length -1) {
                          parent[currentKey] = value;
                      }else {
                          let nextKey = levels[i + 1];
                          if(isNaN(parseInt(nextKey)) === true){
                              parent[currentKey] = {};
                          }else {
                              parent[currentKey] = [];
                          }
                          parent = parent[currentKey];
                      }
                  
                  }
              }
          
          }
      });
  }
  return result;
}

function sanitizeHTML(str) {
	var temp = document.createElement('div');
	temp.textContent = str;
    setTimeout(()=>{temp.remove()},50);
    return temp.innerHTML;
};

function getDropdownText(value,options, defaultText=""){
    if(options == null || !Array.isArray(options)){
        return defaultText;
    }
    let resultIndex = -1;
    let searchResult = options.filter((item,index)=>{
        if(item.value === value){
            resultIndex = index;
        }
        return item.value === value;
    });
    if(searchResult.length === 0){
        return defaultText;
    }else {
        return options[resultIndex].text;
    }
}

function renameKeysForStyle(styleString) {
    let newObj = {};
    let styles = styleString.split(";");
    styles.forEach((item)=>{
        let currLine = item.split(":");
        let key=(currLine[0]||"").trim();
        let content=(currLine[1]||"").trim();
        if(!!key){
            newObj[key] = content;
        }
    });
    const keyValues = Object.keys(newObj).map(key => {
        var camelCased = key.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); });
        return { [camelCased]: newObj[key] };
    });
    return Object.assign({}, ...keyValues);
}