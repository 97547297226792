import React,{Fragment} from "react";
import {Link} from "react-router-dom";

export default function (props) {
    return (
        <Fragment>
        <footer className="footer">
	<div className="shell ">
		<div className="footer__head no_print">
			<a href = "https://dccouncil.gov" className="logo-footer" tabIndex={0} aria-label="Visit DC Council Website" title = "Visit DC Council Website">
				<span className="logo-image" alt = "Visit DC Council Website"></span>
				<span className="hiden">Visit DC Council Website</span>
			</a>	
		</div>

		<div className="footer__inner">
			<nav className="footer__nav no_print">
				<ul>
					<li>
					<a href="https://dccouncil.gov/about-the-council/"  target="_blank">About the Council</a>
					</li>
					<li>
						<a href="https://dccouncil.gov/open-government/"  target="_blank">Open Government</a>
					</li>

					<li>
						<a href="https://dcboe.org/FAQS/Voter-Registration"  target="_blank">Register to Vote</a>
					</li>

					<li>
						<a href="https://dccouncil.gov/council-updates/"  target="_blank">Council Updates</a>
					</li>

					<li>
						<a href="https://dccouncil.gov/jobs-solicitations/"  target="_blank">Jobs</a>
					</li>

					<li>
						<a href="https://dccouncil.gov/council-directory/"  target="_blank">Council Directory</a>
					</li>

					<li>
						<a href="https://dccouncil.gov/privacy-policy/"   target="_blank">Privacy Policy</a>
					</li>

					<li>
						<a href="https://dccouncil.gov/ethics/"   target="_blank">Ethics</a>
					</li>

					<li>
						<a href="https://dccouncil.gov/commemorative-dc-flag-program/"    target="_blank">Commemorative D.C. Flag Program</a>
					</li>
				</ul>
			</nav>

			<div className="socials no_print">
				<ul className="no_print">
					<li>
						<a href="https://www.facebook.com/councilofdc" target="_blank" title="District of Columbia Legislative Information Management System facebook page">
							<span className="ico-facebook">
								<svg id="facebook_icon" role="presentation" alt = "District of Columbia Legislative Information Management System facebook page" 
								aria-label = "District of Columbia Legislative Information Management System facebook page"  xmlns="http://www.w3.org/2000/svg" alt = "facebook icon" width="10" height="19"><path fillRule="evenodd" fill="#FFF" d="M7.325-.007c-1.287 0-2.318.37-3.091 1.11-.774.741-1.161 1.782-1.161 3.126v2.362H.227v3.207h2.846v8.223h3.415V9.798h2.835l.435-3.207h-3.27V4.543c0-.52.112-.91.335-1.17.223-.26.655-.39 1.295-.39H9.87V.123c-.603-.087-1.451-.13-2.545-.13z"></path></svg>
								<span className = "hide" >Some invisiable text, you should not see this on screen</span>
							</span>
						</a>
					</li>
					
					<li>
						<a href="https://twitter.com/councilofdc" target="_blank" title = "District of Columbia Legislative Information Management System twitter page">
							<span className="ico-twitter">
								<svg role="presentation" 
								alt = "District of Columbia Legislative Information Management System twitter page"
								aria-label = "District of Columbia Legislative Information Management System twitter page" xmlns="http://www.w3.org/2000/svg" alt = "twitter icon" width="18" height="15"><path fillRule="evenodd" fill="#FFF" d="M15.838 3.146c.781-.455 1.309-1.098 1.585-1.929a7.183 7.183 0 0 1-2.288.845C14.42 1.326 13.542.957 12.501.957c-.997 0-1.847.341-2.551 1.024-.703.683-1.054 1.508-1.054 2.476 0 .26.029.527.089.802a10.301 10.301 0 0 1-4.146-1.078 10.163 10.163 0 0 1-3.287-2.585 3.358 3.358 0 0 0-.491 1.766c0 .6.145 1.156.435 1.669.29.513.681.928 1.172 1.246a3.668 3.668 0 0 1-1.629-.444v.043c0 .845.273 1.587.82 2.227a3.568 3.568 0 0 0 2.07 1.208 3.854 3.854 0 0 1-.948.119c-.209 0-.436-.018-.681-.054.23.7.654 1.277 1.272 1.728a3.591 3.591 0 0 0 2.098.699c-1.309.997-2.801 1.495-4.475 1.495-.32 0-.61-.014-.871-.043 1.675 1.047 3.52 1.57 5.536 1.57 1.28 0 2.481-.196 3.605-.59 1.123-.394 2.083-.921 2.879-1.582a10.716 10.716 0 0 0 2.06-2.28 10.069 10.069 0 0 0 1.289-2.693 9.691 9.691 0 0 0 .413-3.267 7.45 7.45 0 0 0 1.808-1.809 7.345 7.345 0 0 1-2.076.542z"></path></svg>
								<span className = "hide">Some invisiable text, you should not see this on screen</span>
							</span>
						</a>
					</li>
					
					<li>
						<a href="https://www.youtube.com/user/dccitycouncil" target="_blank" title = "District of Columbia Legislative Information Management System youtube page">
							<span className="ico-youtube">
								<svg role="presentation" 
								alt = "District of Columbia Legislative Information Management System youtube page"
								aria-label = "District of Columbia Legislative Information Management System youtube page" xmlns="http://www.w3.org/2000/svg" alt = "youtube icon" width="20" height="15"><path fillRule="evenodd" fill="#FFF" d="M13.951 8.476l-5.714 3.468a.654.654 0 0 1-.38.108.79.79 0 0 1-.346-.087.643.643 0 0 1-.368-.607V4.424c0-.274.123-.476.368-.607a.694.694 0 0 1 .726.022l5.714 3.467c.223.123.335.318.335.585 0 .268-.112.463-.335.585zm5.943-3.689a13.125 13.125 0 0 0-.24-1.598 2.507 2.507 0 0 0-.776-1.333 2.411 2.411 0 0 0-1.389-.628C15.837 1.048 13.341.957 10 .957s-5.837.091-7.489.271c-.528.058-.99.267-1.384.628a2.524 2.524 0 0 0-.77 1.333 11.63 11.63 0 0 0-.251 1.598 20.29 20.29 0 0 0-.095 1.479C.004 6.656 0 7.198 0 7.891c0 .694.004 1.236.011 1.626.007.389.039.883.095 1.479.056.595.136 1.128.24 1.597.119.528.377.972.776 1.333.398.362.861.571 1.389.629 1.652.18 4.148.27 7.489.27s5.837-.09 7.489-.27a2.384 2.384 0 0 0 1.384-.629c.394-.361.651-.805.77-1.333.111-.469.195-1.002.251-1.597.056-.596.087-1.09.095-1.479.007-.39.011-.932.011-1.626 0-.693-.004-1.235-.011-1.625-.008-.39-.039-.883-.095-1.479z"></path></svg>
								<span className = "hide">Some invisiable text, you should not see this on screen</span>
							</span>
						</a>
					</li>
				</ul>
				<div className="print_only">
					<div>
					<a href="https://www.facebook.com/councilofdc" target="_blank" title="District of Columbia Legislative Information Management System facebook page">
						Our Facebook
						</a>
						<a href="https://twitter.com/councilofdc" target="_blank" title = "District of Columbia Legislative Information Management System twitter page">
							Our Twitter
						</a>
						<a href="https://www.youtube.com/user/dccitycouncil" target="_blank" title = "District of Columbia Legislative Information Management System youtube page">
							Out Youtube
						</a>


					</div>
				</div>
			</div>

			<div className="footer__foot">
				<p className="credits"><strong>Council of the District of Columbia</strong></p>

				<address>1350 Pennsylvania Avenue, NW, Washington, D.C. 20004</address>

				<p className="copyright">&copy; Copyright 2016, The Council of the District of Columbia. All rights reserved</p>
			</div>
			<a className = "hide" aria-hidden={true} tabIndex={-1} href = "/">site map</a>
		</div>
	</div>
</footer>
        </Fragment>
    );
} 